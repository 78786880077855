const iframeRouter = [
  // 碳排放计算 -- 建筑物 -- 建材生产及运输
  // {
  //   path: "/carbon/jzw/scysjd",
  //   name: "scysjd",
  //   component: () => import("../views/iframeView/scysjd.vue"),
  // },
  // // 碳排放计算 -- 建筑物 -- 建造阶段
  // {
  //   path: "/carbon/jzw/jzjd",
  //   name: "jzjd",
  //   component: () => import("../views/iframeView/jzjd.vue"),
  // },
  // // 碳排放计算 -- 建筑物 -- 运行阶段
  // {
  //   path: "/carbon/jzw/yxjd",
  //   name: "yxjd",
  //   component: () => import("../views/iframeView/yxjd.vue"),
  // },
  // // 碳排放计算 -- 建筑物 -- 拆除阶段
  // {
  //   path: "/carbon/jzw/ccjd",
  //   name: "ccjd",
  //   component: () => import("../views/iframeView/ccjd.vue"),
  // },
  // // 碳排放计算 -- 市政基础设施 -- 地面公交
  // {
  //   path: "/carbon/szjcss/dmgj",
  //   name: "dmgj",
  //   component: () => import("../views/iframeView/dmgj.vue"),
  // },
  // // 碳排放计算 -- 市政基础设施 -- 自来水供应
  // {
  //   path: "/carbon/szjcss/zlsgy",
  //   name: "zlsgy",
  //   component: () => import("../views/iframeView/zlsgy.vue"),
  // },
  // // 碳排放计算 -- 市政基础设施 -- 污水处理
  // {
  //   path: "/carbon/szjcss/wscl",
  //   name: "wscl",
  //   component: () => import("../views/iframeView/wscl.vue"),
  // },
  // // 碳排放计算 -- 市政基础设施 -- 燃气供应
  // {
  //   path: "/carbon/szjcss/rqgy",
  //   name: "rqgy",
  //   component: () => import("../views/iframeView/rqgy.vue"),
  // },
  // // 碳排放计算 -- 市政基础设施 -- 道路照明
  // {
  //   path: "/carbon/szjcss/dlzm",
  //   name: "dlzm",
  //   component: () => import("../views/iframeView/dlzm.vue"),
  // },
  // // 碳排放计算 -- 市政基础设施 -- 景观照明
  // {
  //   path: "/carbon/szjcss/jgzm",
  //   name: "jgzm",
  //   component: () => import("../views/iframeView/jgzm.vue"),
  // },
  // // 碳排放计算 -- 市政基础设施 -- 垃圾处理
  // {
  //   path: "/carbon/szjcss/ljcl",
  //   name: "ljcl",
  //   component: () => import("../views/iframeView/ljcl.vue"),
  // },
  // // 碳排放计算 -- 生产企业 -- 发电企业
  // {
  //   path: "/carbon/scqy/fdgy",
  //   name: "fdgy",
  //   component: () => import("../views/iframeView/fdgy.vue"),
  // },
  // // 碳排放计算 -- 生产企业 -- 民航企业
  // {
  //   path: "/carbon/scqy/mhqy",
  //   name: "mhqy",
  //   component: () => import("../views/iframeView/mhqy.vue"),
  // },
  // // 碳排放计算 -- 生产企业 -- 水泥生产企业
  // {
  //   path: "/carbon/scqy/snscqy",
  //   name: "snscqy",
  //   component: () => import("../views/iframeView/snscqy.vue"),
  // },
  // // 碳排放计算 -- 生产企业 -- 电网企业
  // {
  //   path: "/carbon/scqy/dwqy",
  //   name: "dwqy",
  //   component: () => import("../views/iframeView/dwqy.vue"),
  // },
  // // 碳排放计算 -- 生产企业 -- 钢铁生产企业
  // {
  //   path: "/carbon/scqy/gtscqy",
  //   name: "gtscqy",
  //   component: () => import("../views/iframeView/gtscqy.vue"),
  // },
  // // 碳排放计算 -- 生产企业 -- 造纸和纸制品生产企业
  // {
  //   path: "/carbon/scqy/zzpscqy",
  //   name: "zzpscqy",
  //   component: () => import("../views/iframeView/zzpscqy.vue"),
  // },
  // // 碳排放计算 -- 生产企业 -- 其他有色金属冶炼和延压加工企业
  // {
  //   path: "/carbon/scqy/ysjsjgqy",
  //   name: "ysjsjgqy",
  //   component: () => import("../views/iframeView/ysjsjgqy.vue"),
  // },
  // // 碳排放计算 -- 生产企业 -- 石油化工企业
  // {
  //   path: "/carbon/scqy/syhgqy",
  //   name: "syhgqy",
  //   component: () => import("../views/iframeView/syhgqy.vue"),
  // },
  // // 碳排放计算 -- 生产企业 -- 化工企业
  // {
  //   path: "/carbon/scqy/hgqy",
  //   name: "hgqy",
  //   component: () => import("../views/iframeView/hgqy.vue"),
  // },
  // // 碳排放计算 -- 生产企业 -- 工业其他行业企业
  // {
  //   path: "/carbon/scqy/gthyqy",
  //   name: "gthyqy",
  //   component: () => import("../views/iframeView/gthyqy.vue"),
  // },
  // // 碳排放计算 -- 生产企业 -- 公共建筑运营单位
  // {
  //   path: "/carbon/scqy/ggjzyydw",
  //   name: "ggjzyydw",
  //   component: () => import("../views/iframeView/ggjzyydw.vue"),
  // },
  // // 碳排放计算 -- 生产企业 -- 镁冶炼企业
  // {
  //   path: "/carbon/scqy/mylqy",
  //   name: "mylqy",
  //   component: () => import("../views/iframeView/mylqy.vue"),
  // },
  // // 碳排放计算 -- 生产企业 -- 陆上交通运输企业
  // {
  //   path: "/carbon/scqy/lsjtysqy",
  //   name: "lsjtysqy",
  //   component: () => import("../views/iframeView/lsjtysqy.vue"),
  // },
  // // 碳排放计算 -- 生产企业 -- 陶瓷生产企业
  // {
  //   path: "/carbon/scqy/tcscqy",
  //   name: "tcscqy",
  //   component: () => import("../views/iframeView/tcscqy.vue"),
  // },
  // // 碳排放计算 -- 生产企业 -- 平板玻璃生产企业
  // {
  //   path: "/carbon/scqy/pbglscqy",
  //   name: "pbglscqy",
  //   component: () => import("../views/iframeView/pbglscqy.vue"),
  // },
  // // 碳排放计算 -- 生产企业 -- 食品烟草及酒饮料和精制茶叶企业
  // {
  //   path: "/carbon/scqy/spycqy",
  //   name: "spycqy",
  //   component: () => import("../views/iframeView/spycqy.vue"),
  // },
  // // 碳排放计算 -- 生产企业 -- 电解铝生产企业
  // {
  //   path: "/carbon/scqy/djlscqy",
  //   name: "djlscqy",
  //   component: () => import("../views/iframeView/djlscqy.vue"),
  // },
  // // 碳排放计算 -- 生产企业 -- 电子设备制造企业
  // {
  //   path: "/carbon/scqy/dzsbzzqy",
  //   name: "dzsbzzqy",
  //   component: () => import("../views/iframeView/dzsbzzqy.vue"),
  // },
  // // 碳排放计算 -- 生产企业 -- 机械设备制造企业
  // {
  //   path: "/carbon/scqy/jxsbzzqy",
  //   name: "jxsbzzqy",
  //   component: () => import("../views/iframeView/jxsbzzqy.vue"),
  // },
  // // 碳排放计算 -- 生产企业 -- 氟化工企业
  // {
  //   path: "/carbon/scqy/fhgqy",
  //   name: "fhgqy",
  //   component: () => import("../views/iframeView/fhgqy.vue"),
  // },
  // // 碳排放计算 -- 生产企业 -- 矿山企业
  // {
  //   path: "/carbon/scqy/ksqy",
  //   name: "ksqy",
  //   component: () => import("../views/iframeView/ksqy.vue"),
  // },
  // // 碳排放计算 -- 生产企业 -- 独立焦化企业
  // {
  //   path: "/carbon/scqy/dljhqy",
  //   name: "dljhqy",
  //   component: () => import("../views/iframeView/dljhqy.vue"),
  // },
  // // 碳排放计算 -- 生产企业 -- 石油天然气生产企业
  // {
  //   path: "/carbon/scqy/sytrqscqy",
  //   name: "sytrqscqy",
  //   component: () => import("../views/iframeView/sytrqscqy.vue"),
  // },
  // // 碳排放计算 -- 生产企业 -- 煤炭生产企业
  // {
  //   path: "/carbon/scqy/mtscqy",
  //   name: "mtscqy",
  //   component: () => import("../views/iframeView/mtscqy.vue"),
  // },
  // // 我的碳排放
  // {
  //   path: "/culatetask/my",
  //   name: "myCulatetask",
  //   component: () => import("../views/iframeView/myCulatetask.vue"),
  // },
  // // 碳排放核算
  // {
  //   path: "/culatetask",
  //   name: "culatetask",
  //   component: () => import("../views/iframeView/culatetask.vue"),
  // },
  // // 优化分析 -- 全生命周期低碳运营策略
  // {
  //   path: "/optimize/jjxy",
  //   name: "jjxy",
  //   component: () => import("../views/iframeView/jjxy.vue"),
  // },
  // // 优化分析 -- 可靠性运营低碳优化策略
  // {
  //   path: "/optimize/kkcd",
  //   name: "kkcd",
  //   component: () => import("../views/iframeView/kkcd.vue"),
  // },
  // // 优化分析 -- 综合性低碳管理绩效评价
  // {
  //   path: "/optimize/hjyx",
  //   name: "hjyx",
  //   component: () => import("../views/iframeView/hjyx.vue"),
  // },
];

export default iframeRouter;
