const pageRouter = [
  // 监测点列表
  {
    path: "/spotList",
    name: "spotList",
    component: () => import("../views/uploadSpotManage/spotList.vue"),
  },
  // 创建监测点
  {
    path: "/spotAdd",
    name: "spotAdd",
    component: () => import("../views/uploadSpotManage/spotAdd.vue"),
  },
  // 修改监测点
  {
    path: "/spotModify/:id",
    name: "spotModify",
    component: () => import("../views/uploadSpotManage/spotModify.vue"),
  },
  // 查看监测点
  {
    path: "/spotSee/:id",
    name: "spotSee",
    component: () => import("../views/uploadSpotManage/spotSee.vue"),
  },
  // 3D看板页面
  {
    path: "/pages3D",
    name: "pages3D",
    component: () => import("../views/pages3D.vue"),
  },

  // 
  // {
  //   path: "/uploadData",
  //   name: "uploadData",
  //   component: () => import("../views/uploadData/index.vue"),
  // },
  // 列表
  {
    path: "/uploadData",
    name: "uploadData",
    component: () => import("../views/uploadData/index.vue"),
  },
  {
    path: "/uploadData/:id",
    name: "uploadData",
    component: () => import("../views/uploadData/index.vue"),
  },
  // 列表
  {
    path: "/uploadDataList",
    name: "uploadDataList",
    component: () => import("../views/uploadData/UploadDataList.vue"),
  },
  // // 生产
  // {
  //   path: "/uploadDataDevelop",
  //   name: "uploadDataDevelop",
  //   component: () => import("../views/uploadData/UploadDataDevelop.vue"),
  // },
  // // 生产-修改
  // {
  //   path: "/uploadDataDevelop/:id",
  //   name: "uploadDataDevelop",
  //   component: () => import("../views/uploadData/UploadDataDevelop.vue"),
  // },
  // // 运行
  // {
  //   path: "/uploadDataProcess",
  //   name: "uploadDataProcess",
  //   component: () => import("../views/uploadData/UploadDataProcess.vue"),
  // },
  // // 运行-修改
  // {
  //   path: "/uploadDataProcess/:id",
  //   name: "uploadDataProcess",
  //   component: () => import("../views/uploadData/UploadDataProcess.vue"),
  // },
  // // 拆除
  // {
  //   path: "/uploadDataDestory",
  //   name: "uploadDataDestory",
  //   component: () => import("../views/uploadData/UploadDataDestory.vue"),
  // },
  // // 拆除-修改
  // {
  //   path: "/uploadDataDestory/:id",
  //   name: "uploadDataDestory",
  //   component: () => import("../views/uploadData/UploadDataDestory.vue"),
  // },
];

export default pageRouter;
