import pageRouter from './pageRouter'
import iframeRouter from './iframeRouter'
let arr = []
arr = arr.concat(pageRouter,iframeRouter)
const baseRouter = [
    {
        path: '/',
        name: 'login',
        component: () => import('../views/Login.vue')
    },
    {
        path:'*',
        component:() => import('../components/chromeError.vue')
    },
    {
        path: '/index',
        name: 'home',
        component: () => import('../views/HomeView.vue'),
        meta:{title:'运维平台'},
        children:arr
    },
]

export default baseRouter