/* eslint-disable no-param-reassign */
import axios from 'axios';
import { Message } from 'element-ui';
import router from '@/router';
// import Cookies from 'js-cookie';
// import { getCookie, setCookie } from './cookie';

// import { storeOriginUrl } from './tool'
const service = axios.create({
  timeout: 50000,
  baseURL: process.env.NODE_ENV === 'production' ?'http://test.taurus.chasingbrilliant.com':'/api',
  headers: {
    // Authorization: getCookie('Authorization'),
    // 'Access-Control-Request-Private-Network': true,
    post: {
      'Content-Type': 'application/json',
    },
  },
  // withCredentials: true,
});
// axios({
//   method: 'post',
//   baseURL:'/api',
//   url: '/wms/sys/login',
//   data: {account:'admin',password:'e10adc3949ba59abbe56e057f20f883e'}
// }).then(res=>{
//   console.log(res);
// }).catch(err=>{
//   console.log(err);
// })
// http request 拦截器
service.interceptors.request.use(
  // eslint-disable-next-line no-unused-vars
  (config) => {
    return config;
  },
  () => {
    Message.error('加载超时，请重试；');
  },
);

// http response 拦截器
service.interceptors.response.use(
  (response) => {
    // console.log(response)
    if(response.data && response.data.code === 10002){
      router.push('/login')
    }
    return response;
  },
  (err) => {
    const errStr = JSON.stringify(err);
    const msg = JSON.parse(JSON.stringify(err)).message;
    if (msg.includes('401')) {
      Message.error('登录超时，请重新登录！');
      router.push('/login');
      // Cookies.remove('Authorization', {
      //   path: '/',
      //   domain: '.xxx.com.cn',
      // });
    } else if (msg.includes('403')) {
      Message.error('暂无权限，请联系管理员！');
      router.push('/loadfail');
    }
    if (
      errStr.includes('Network Error') &&
      /chrome/i.test(navigator.userAgent)
    ) {
      Message.error('加载超时，请重试!');
      router.push('/chromeError');
    } else {
      Message.error('加载超时，请重试！');
    }
    localStorage.clear();
  },
);

export function get(url, params = {}, isIntercept = true) {
  return new Promise((resolve, reject) => {
    service
      .get(url, {
        params,
      })
      .then((response) => {
        response = response.data;
        if (isIntercept && response.code !== 1) {
          Message.error({
            message: response.message,
          });
          localStorage.clear();
          reject(response);
        } else {
          resolve(response);
        }
      })
      .catch((err) => {
        localStorage.clear();
        reject(err);
      });
  });
}

export function post(url, data = {}, isIntercept = true) {

  
    // const form = new FormData();
    // Object.entries(data).forEach(([k, v]) => {
    //   form.append(k, v);
    // });
  return new Promise((resolve, reject) => {
    service
      .post(url, data)
      .then((response) => {
        response = response.data;
        if (isIntercept && response.code !== 1) {
          reject(response);
        } else {
          resolve(response);
        }
      })
      .catch((err) => {
        reject(err);
      });
  });
}

// export const requestHttps = (type, url, data) => {
// 	return new Promise((resolve, reject) => {
// 		service({
// 			headers: {
// 				'Content-Type': type === 'get' ? 'application/x-www-form-urlencoded;charset=UTF-8' :
// 					'application/json;charset=UTF-8'
// 			},
// 			method: type,
// 			url: url,
// 			[type === 'get' ? 'params' : 'data']: data,
// 		}).then(res => {
// 			resolve(res);
// 		}).catch(err => {
// 			reject(err);
// 		})
// 	});
// }
