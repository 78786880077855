import Vue from 'vue'
import App from './App.vue'
import router from './router'
import './plugins/element.js'
import '@/assets/css/common.scss';
import {get, post} from '@/utils/request'


Vue.config.productionTip = false
Vue.prototype.$get = get
Vue.prototype.$post = post



var app = new Vue({
  router,
  render: h => h(App)
}).$mount('#app')


