<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>

export default {
  name: 'app',
  data (){
    return {
      isLogin: false,
    }
  }
  
}
</script>

<style lang="less" >
@import './assets/css/reset.css';
</style>