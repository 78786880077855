import Vue from 'vue'
import VueRouter from 'vue-router'
import baseRouter from './baseRouter'

Vue.use(VueRouter)

const router = new VueRouter({
    routes: baseRouter
})

// router.beforeEach((to, from, next) => {
//   // 解决刷新，参数消失问题
//   if (to.path === '/login' || to.path === '/loadfail') {
//     next();
//     // 跳转到登录页刷新一下
//     return;
//   }
//   const Uid = Cookies.get('Authorization');
//   if (!Uid) {
//     if (
//       location.pathname !== '/login' &&
//       location.pathname !== '/loadfail' &&
//       location.pathname !== '/loginout'
//     ) {
//       router.push('/login');
//     }
//   } else {
//     next();
//   }
// });

export default router
